.Login-container {
  display: flex;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  font-size: 16px;
}

.Login-form-container {
  background-color: #cc0000;
  margin: 0 auto;
  padding: 24px;
  width: 450px;
  max-width: 100%;
}

.Login-form-container__logo {
  max-width: 150px;
  margin: auto;
  display: block;
  margin-bottom: 32px;
}

.button-col {
  justify-content: flex-end;
}

.button-forgot {
  padding-left: 0;
  padding-bottom: 0;
  color: white;
  text-decoration: underline;
  font-size: 14px;
}

.form-label.login-label {
  color: white;
}

.float-right.w-75 {
  padding: 0px !important;
  background-color: #00803e;
  border-color: #00803e;
  font-size: 16px;
}

.float-right.w-50 {
  padding: 5px !important;
  background-color: #00803e;
  border-color: #00803e;
  font-size: 16px;
}

.login-confirm-text {
  color: white;
  margin-bottom: 30px;
}

.helptext {
  color: white;
  padding-top: 10px;
  font-size: 12px;
}
