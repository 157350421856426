input.DateInput_input {
  padding: 0;
  border: 0;
  line-height: 1.5;
  color: black;
  border-radius: 0.2rem;
}
.DateRangePickerInput {
  padding: 2px 0;
  font-size: 0.875rem;
  line-height: 1em;
}

.DateRangePickerInput__withBorder {
  border-color: #707070;
  border-radius: 3px;
}

.DateRangePickerInput {
  display: flex;
}

.DateRangePickerInput_arrow {
  display: inline;
  vertical-align: middle;
  font-weight: 300;
  font-size: 1.5em;
  padding-top: 1px;
  color: #707070;
}

.DateRangePickerInput_arrow_svg {
  height: 16px;
  width: auto;
}

.DateInput_input {
  font-weight: normal;
  border-radius: 0.2rem;
  font-size: 0.875rem;
  line-height: 1em;
}

.DateInput {
  padding: 0 8px;
}

.DateRangePickerInput_calendarIcon {
  padding: 0;
  line-height: 1em;
}

/* Calendar view */
.DateInput_fang {
  height: 8px;
}

.CalendarMonth_caption {
  padding-bottom: 48px;
}

.CalendarDay__default {
  color: #000;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background-color: #00803e;
  border-color: #00803e;
  color: #fff;
}

.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  color: #777;
}

.CalendarDay__selected_span {
  background-color: #00803e1a;
  border-color: #00803e1a;
}
