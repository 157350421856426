.nav-col {
  background-color: #cc0000;
  padding: 0;
  min-width: 152px;
}

.nav-col__logo {
  width: 100%;
  height: auto;
  padding: 16px 32px;
}

.nav-item {
  width: 100%;
}

.nav-button {
  display: block;
  text-align: left;
  color: white;
  border-bottom: 1px solid #cc3d3d;
  border-radius: 0;
  text-decoration: none;
  width: 100%;
  padding: 14px;
  transition: all 0.2s;
}

.nav-button:focus,
.nav-button:hover,
.nav-button.active {
  color: #cc0000;
  background-color: white;
  text-decoration: none;
  border-color: white;
}
