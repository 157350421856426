.view-all-btn {
  background-color: #00803e;
  border: none;
  padding: 2px 12px;
  font-size: 16px;
}

.view-all-btn:hover {
  background-color: #00803e !important;
  border: none;
}

.export-btn {
  background-color: #cc0000;
  border: 1px solid #cc0000;
}

div.triple.btn-group {
  padding: 0 !important;
}

.action-btn {
  padding: 0 5px;
  background-color: white;
  border: 1px solid #00803e;
  color: #00803e;
  font-size: 16px;
}

.action-btn:hover {
  background-color: white;
  border: 1px solid #00803e;
  color: #00803e;
}

.header-btn {
  padding: 0 12px;
  background-color: #cc0000;
  border: none;
  font-size: 16px;
}

.view-all-btn:hover {
  background-color: #cc0000;
  border: none;
}

.delete-loc-btn {
  background-color: white;
  color: #8b0000;
  border-color: #8b0000;
}

.delete-loc-btn:hover {
  background-color: white;
  color: #8b0000;
  border-color: #8b0000;
}

.btn--primary {
  background-color: #00803e;
  border-color: #00803e;
}

.btn--primary:hover {
  background-color: #00803e;
  color: white;
  border-color: #00803e;
}

.view-btn,
.edit-btn {
  padding: 0 15px;
}

.click-here {
  padding: 0;
  border: none;
  line-height: 16px;
  vertical-align: baseline;
  color: #00803e;
}
