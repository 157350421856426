.views-col {
  padding: 10px 0;
  background-color: #f6f6f6;
  overflow: hidden;
}

.dashboard-container {
  height: 100vh;
}

.container-row {
  height: 100%;
  width: 100%;
}

.view-col {
  overflow-x: hidden;
  padding: 24px 12px !important;
  background-color: #f6f6f6;
}

.dashboard-row {
  margin-bottom: 30px;
}

.dashboard-header {
  font-weight: bold;
  color: #cc0000;
  margin-bottom: 10px;
  font-size: 18px;
  justify-content: space-between;
}

.dashboard-header p {
  margin-bottom: 0;
}

.dashboard-header-col {
  display: flex;
  margin-right: 10px;
  margin-top: auto;
}

.dashboard-header-col p {
  margin: 0;
}

.dashboard-header-col__row {
  align-items: flex-start;
}

.dashboard-header-col__row > * {
  margin-bottom: 8px;
}

.data-title {
  background-color: black;
  color: white;
  width: 100%;
  padding: 5px 5px;
  padding-top: 5px !important;
}

.data-title p {
  padding: 0;
  margin: 0;
}

.data-body {
  background-color: white;
  color: black;
  flex: 1;
}

.inner-col {
  padding: 0;
  display: flex;
  flex-direction: column;
}

.audit-status {
  text-align: center;
  color: white;
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.audit-status .percentage {
  font-size: 50px;
  font-weight: bold;
}

.audit-status .row {
  padding: 0 12px 8px;
  font-size: 16px;
}

.dropdown-toggle {
  position: relative;
  background-color: white;
  color: #707070;
  padding-right: 24px;
  text-align: left;
  border-color: #707070;
}

.dropdown-toggle:hover {
  background-color: white;
  color: #707070;
}

.dropdown-toggle::after {
  position: absolute;
  right: 0.5em;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown-item:hover {
  background-color: #cc0000;
  color: white;
}

.dropdown-item:active {
  background-color: #cc0000;
  color: white;
}

.widerow-listgroup {
  width: 100%;
}

.widerow-listgroup .list-group-item {
  padding: 5px 0;
  border-radius: 0;
  border-bottom: 0;
  border-right: 0;
  border-left: 0;
  position: relative;
}

.first-col,
.last-col {
  margin-left: 20px;
}

.data-title .first-col {
  margin-left: 15px;
}

.widerow-listgroup .list-group-item:first-child {
  border-top: 0;
}

.dashboard-card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .dashboard-card:not(:last-child) {
    margin-bottom: 24px;
  }
}

.mcfs-row {
  height: 100%;
}

.mcfs.data-body .list-group {
  width: 100%;
}

.mcfs.data-body .list-group .list-group-item {
  border-radius: none;
  border: none;
  padding: 8px 10px;
}

.mcfs.data-body .empty {
  padding: 8px 10px;
}

.question-text {
  padding: 0 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.question-text.col {
  padding: 0 10px;
}

.pass {
  background-color: #00803e;
  height: 100%;
}

.fail {
  background-color: #cc0000;
}

.flag {
  color: #cc0000;
}

.mcfs-row .inner-col {
  padding: 0 8px;
}

@media screen and (max-width: 767px) {
  .mcfs-row .inner-col:not(:last-child) {
    margin-bottom: 24px;
  }
}

.col.empty {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.wide1 .col.empty {
  min-height: 300px;
}

.audit-container {
  padding: 0 15px;
}

.audit-card {
  margin-bottom: 20px;
}

.audit-title-row {
  margin-bottom: 15px;
}

.audit-inner-col {
  background-color: white;
}

.audit-header {
  /* background-color: #cc0000; */
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.audit-body {
  padding: 8px;
  background-color: #fff;
}

.audit-header span {
  margin-right: 8px;
  font-weight: 700;
}

.audit-card__image-row {
  justify-content: space-between;
  margin-bottom: 16px;
}

.audit-title-first p {
  padding-left: 5px;
}

.audit-header-first p {
  padding-left: 10px;
}

.audit-header-second p {
  padding-left: 10px;
}

.images-row {
  padding: 0;
}

.row.no-padding {
  padding: 0px !important;
}

.bottom-col {
  padding: 10px !important;
  background-color: darkgray;
}

.bottom-title {
  padding-bottom: 5px;
  font-weight: bold;
}

.photo-title {
  font-weight: bold;
}

@media only screen and (min-width: 1200px) {
  .photo-title {
    width: 120px;
  }
}

.black-title {
  color: black;
}

.angle-right {
  color: darkgray;
  font-size: 16px;
}

.red-title {
  color: #cc0000;
}

.audit-flag {
  color: #cc0000;
}

.flag-header {
  font-size: 16px;
  position: relative;
  padding-left: 10px;
  color: black;
  font-weight: normal;
}

.flag-header p {
  padding-left: 10px;
}

.last-buttons .col .header-btn {
  float: right;
}

.header-btn:active,
.header-btn:hover,
.header-btn:focus {
  background-color: #cc0000;
  border-color: #cc0000;
}

.expand-btn.btn-primary {
  background-color: black;
  border-color: black;
  padding: 0;
  font-size: 12px;
}

.expand-btn.btn-primary:focus {
  box-shadow: none;
}

.hide {
  display: none !important;
}

.username {
  padding-left: 10px !important;
}

.edit-container {
  background-color: white;
  padding: 10px !important;
}

.save-change .btn {
  float: right;
}

.validation-loc {
  padding-bottom: 30px;
}

.bottom-buttons {
  padding-bottom: 25px;
}

.support-inner {
  background-color: white;
  padding: 15px !important;
  padding-bottom: 200px !important;
}

.support-header {
  font-weight: bold;
  border-bottom: 2px solid #cc0000;
}

.support-col {
  padding-bottom: 15px;
}

a {
  color: #00803e;
}

.pdf {
  color: #cc0000;
  font-size: 16px;
}

.pdf a {
  color: #cc0000;
  font-size: 16px;
}

.nav-button:focus {
  color: #cc0000;
  background-color: white;
}

.form-label {
  color: black;
}

.pagination {
  justify-content: center;
  margin-top: 16px;
}

.pagination .page-link {
  color: #cc0000;
}

.page-item.active .page-link {
  background-color: #cc0000;
  border-color: #cc0000;
}

.page-item:last-child .page-link {
  border-radius: 0;
}

.page-item:first-child .page-link {
  border-radius: 0;
}

.show > .btn-primary.dropdown-toggle {
  background-color: white;
  color: #707070;
}

.image-modal .modal-dialog {
  height: 40%;
}

.image-modal .modal-dialog .modal-content {
  background-color: #707070;
  height: 100%;
  width: 100%;
}

.image-modal .modal-dialog .modal-content .modal-header {
  border-bottom: none;
}

.image-modal .modal-dialog .modal-content .modal-header button.close {
  background-color: #cc0000;
  border-radius: 50px;
  padding: 0;
  width: 30px;
  height: 30px;
  text-shadow: none;
  position: absolute;
  top: 2px;
  right: 5px;
  opacity: 1;
  color: white;
  font-weight: bolder;
}

.image-modal .modal-content .modal-body {
  overflow: scroll;
}

.image-modal .modal-content .modal-body .modal-image {
  max-width: 100%;
}

.modal-content {
  border-radius: 5px;
}

.modal-footer .btn-primary {
  background-color: #cc0000;
  border-color: #cc0000;
}

.modal-footer .btn-secondary {
  color: #707070;
  background-color: white;
  border-color: #707070;
}

.auditor-col {
  background-color: #e6e6e6;
}

p.auditor-comments {
  max-width: 290px;
}

.image-button {
  padding: 0;
  border: none;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 0;
  background-color: white;
}

.image-button .card {
  border-radius: 0;
  border-color: #707070;
  background-color: #707070;
}

.u-center {
  text-align: center;
}

.u-padding-md {
  padding: 40px 0;
}
